import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)


export default new Vuex.Store({
  state: {
    language: 'en'
  },
  mutations: {
    languageTrigger(state, body) {
      state.language = body
    }
  },
  actions: {
  },
  getters: {
    getLanguage(state) {
      return state.language
    }
  },
  modules: {
  }
})
