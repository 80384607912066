<template>
  <div class="home">
    <div>
      <h1>{{ translate("brand") }}</h1>
      <h6>{{ translate("web_status") }}</h6>
      <button @click="changeLanguage('th')">TH</button>
      <button @click="changeLanguage('en')">EN</button>
    </div>
  </div>
</template>

<script>
import translationFn from "../utils/translation";
export default {
  name: "Home",
  methods: {
    translate(key) {
      return translationFn(key);
    },
    changeLanguage(key) {
      this.$store.commit("languageTrigger", key);
    },
  },
};
</script>

<style scoped>
h1 {
  margin: 0;
}
h6 {
  margin: 0;
}
button {
  margin: 3px;
}
.home {
  background: linear-gradient(305deg, #ec2f1a, #1b25bf, #e89c00, #ea03c3);
  background-size: 800% 800%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 60px;
  font-weight: bold;
  color: white;

  -webkit-animation: AnimationName 7s ease infinite;
  -moz-animation: AnimationName 7s ease infinite;
  -o-animation: AnimationName 7s ease infinite;
  animation: AnimationName 7s ease infinite;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 32% 0%;
  }
  50% {
    background-position: 69% 100%;
  }
  100% {
    background-position: 32% 0%;
  }
}
@-moz-keyframes AnimationName {
  0% {
    background-position: 32% 0%;
  }
  50% {
    background-position: 69% 100%;
  }
  100% {
    background-position: 32% 0%;
  }
}
@-o-keyframes AnimationName {
  0% {
    background-position: 32% 0%;
  }
  50% {
    background-position: 69% 100%;
  }
  100% {
    background-position: 32% 0%;
  }
}
@keyframes AnimationName {
  0% {
    background-position: 32% 0%;
  }
  50% {
    background-position: 69% 100%;
  }
  100% {
    background-position: 32% 0%;
  }
}

@media only screen and (max-width: 375px) {
  h1 {
    font-size: 64px;
    margin: 0;
  }
  h6 {
    font-size: 24px;
    margin: 0;
  }
}
</style>
